import React, { MouseEvent } from 'react'

export interface ISettingsIconProps {
  color?: string,
  onClick: (event: MouseEvent<SVGSVGElement>) => void,
}
const defaultProps = {
  color: '#000',
  onClick: () => { },
}

export default function DeleteIcon(props: ISettingsIconProps = defaultProps) {
  const {
    color,
    onClick,
  } = props

  return (
    <svg
      fill={color}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}
